import {React, useState} from "react";



function CalculusOneEntry({endpoint, placeHolder1}){
    const [searchItem1, setSearchItem1] = useState('')
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');

        const item1 = searchItem1.replace("/", "|");

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/calculus/${endpoint}/${item1}`;
   
        if(item1 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <div className="space-y-2">
                <input  type="text" placeholder={placeHolder1} className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}


function CalculusTwoEntries({endpoint, placeHolder1, placeHolder2}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')

    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1.replace("/", "|");;
        const item2 = searchItem2.replace("/", "|");;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/calculus/${endpoint}/${item1}/${item2}`;
   
        if(item1 !== '' && item2 !== '' ){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <div className="space-y-2">
                <input  type="text" placeholder={placeHolder1} className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder2} className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-red-500 hover:bg-red-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function CalculusThreeEntries({endpoint, placeHolder1, placeHolder2, placeHolder3}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [searchItem3, setSearchItem3] = useState('')

    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1.replace("/", "|");;
        const item2 = searchItem2.replace("/", "|");;
        const item3 = searchItem3.replace("/", "|");;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/calculus/${endpoint}/${item1}/${item2}/${item3}`;
   
        if(item1 !== '' && item2 !== '' && item3 !== '' ){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <div className="space-y-2">
                
                <input  type="text" placeholder={placeHolder1} className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder2} className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder3} className="input input-bordered w-full"  value={searchItem3} onChange={event => setSearchItem3(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-purple-600 hover:bg-purple-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function CalculusOneMatrixEntry({endpoint}){
    const [searchItem1, setSearchItem1] = useState('')
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');

        var item1 = searchItem1.replace("/", "|").replace(/\n\r/g, ";").replace(/\n/g, ";"); 
        //we add ; add the end of each line before sending to server

console.log(item1);
        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/calculus/${endpoint}/${item1}`;
   
        if(item1 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <div className="space-y-2">
            <textarea className="textarea textarea-bordered  w-full h-48" placeholder="Type in a matrix" value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></textarea>
            </div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full h-48" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}



function CalculusOneMatrixInputEntry({endpoint}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');

        const item1 = searchItem1.replace("/", "|").replace(/\n\r/g, ";").replace(/\n/g, ";"); //we add ; add the end of each line before sending to server;
        const item2 = searchItem2.replace("/", "|");
        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/calculus/${endpoint}/${item1}/${item2}`;
   
        if(item1 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <div className="space-y-2">
            <textarea className="textarea textarea-bordered  w-full h-48" placeholder="Type in a matrix" value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></textarea>
            <input  type="text" placeholder={"Enter an number n"} className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-purple-600 hover:bg-purple-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full h-48" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}



function CalculusTwoMatricesEntries({endpoint, sign}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1.replace(/\n\r/g, ";").replace(/\n/g, ";"); //we add ; add the end of each line before sending to server;
        const item2 = searchItem2.replace(/\n\r/g, ";").replace(/\n/g, ";"); //we add ; add the end of each line before sending to server;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/calculus/${endpoint}/${item1}/${item2}`;
   
        if(item1 !== '' && item2 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        } 
    }

    return(
        <div>

            <div className="flex flex-row py-2">
                <div className="basis-3/7">
                <textarea className="textarea textarea-bordered  w-full ml-2 h-48" placeholder="Type in a matrix A" value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></textarea>
                </div>
                <div className="basis-1/7">
                    <p className="items-center m-5 mt-20">{sign}</p>
                </div>
                <div className="basis-3/7">
                <textarea className="textarea textarea-bordered mr-1 w-full h-48" placeholder="Type in a matrix B" value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></textarea>
                </div>
                
            </div>
            <div className="py-2">
                <button className="btn bg-red-500 hover:bg-red-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full h-48" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}


function CalculusLinearSystems({endpoint, sign}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1.replace(/\n\r/g, ";").replace(/\n/g, ";"); //we add ; add the end of each line before sending to server;
        const item2 = searchItem2.replace(/\n\r/g, ";").replace(/\n/g, ";"); //we add ; add the end of each line before sending to server;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/calculus/${endpoint}/${item1}/${item2}`;
   
        if(item1 !== '' && item2 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        } 
    }

    return(
        <div>

            <div className="flex flex-row py-2">
                <div className="basis-3/5">
                <textarea className="textarea textarea-bordered  w-full ml-2 h-48" placeholder="Matrix A" value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></textarea>
                </div>
                <div className="basis-1/5">
                    <p className="items-center m-5 mt-20">{sign}</p>
                </div>
                <div className="basis-1/5">
                <textarea className="textarea textarea-bordered mr-1 w-full h-48" placeholder="M. B" value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></textarea>
                </div>
                
            </div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full h-48" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}



function AngouriPolynomialGuide(){
    return (
        <>
            <div className="card-body">
                
                <p>Use the following guideline to correctly enter functions and polynomials : </p>
                <p><b>Basic operators</b>: use <span className="text-sky-500 hover:text-sky-600">+ - * / </span> respectively for addition, subtraction, product and division. </p>
                <p><b>Power</b>: use <span className="text-sky-500 hover:text-sky-600">^ </span> x² should be typed in as <span className="text-sky-500 hover:text-sky-600">x^2</span> </p>
                <p><b>Variable</b>: use lowercase letters <span className="text-sky-500 hover:text-sky-600">x</span> or y (for sytem of equations) or any other.</p>
                <p><b>Square root</b>: use <span className="text-sky-500 hover:text-sky-600">sqrt</span> . example: sqrt(x)</p>
                <p><b>Trigonometric functions</b>: use <span className="text-sky-500 hover:text-sky-600">cos(x), sin(x), tan(x), tanh(x) ...</span></p>
                <p><b>Exponential</b>: use <span className="text-sky-500 hover:text-sky-600">e</span> . example: e^2 </p>
                <p><b>Infinity</b>: to find limit when x tends towards infinity, use <span className="text-sky-500 hover:text-sky-600">+oo</span> or <span className="text-sky-500 hover:text-sky-600">-oo</span>. </p>
            </div>
        </>
    )
}



function AngouriMatrixGuide(){
    return (
        <>
            <div className="card-body">
                
                <p>Use the following guideline to correctly enter matrices: </p>
                <p>Matrix values must be comma-separated but no comma at the end of a row. </p>
                <p>Example of multi-row and multi-column matrix:</p>
                <p><span className="text-sky-500 hover:text-sky-600">1, 2, 4, 6</span></p>
                <p><span className="text-sky-500 hover:text-sky-600">3, 1, 7, 9</span></p>
                <p><span className="text-sky-500 hover:text-sky-600">5, 8, 6, 1</span></p><hr />
                <p>Example of a one-row matrix:</p>
                <p><span className="text-red-500 hover:text-red-600">1, 2, 4, 6</span></p><hr />
                <p>Example of a one-column matrix:</p>
                <p><span className="text-purple-500 hover:text-purple-600"> 4</span></p>
                <p><span className="text-purple-500 hover:text-purple-600"> 9</span></p>
                <p><span className="text-purple-500 hover:text-purple-600"> 6</span></p><hr />
                <p>You can only compute the <b>inverse, trace, diagonal</b> of a <span className="text-blue-500 hover:text-blue-600">square matrix</span>.</p>
                <p>You can only <b>add, subtract</b> <span className="text-purple-500 hover:text-purple-600">two matrices of same dimensions</span>.</p>
                <p>You can only <b>multiply</b> two matrices when <span className="text-red-500 hover:text-red-600">
                the matrices are compatible: The number of columns in the first matrix must be the same as the number of rows in the second matrix
                    </span>.</p>
            </div>
        </>
    )
}


function LinearSystemGuide(){
    return (
        <>
            <div className="card-body">
                
                <p>Use the following guideline to correctly enter matrices: </p>
                <p>Matrix values must be comma-separated but no comma at the end of a row. </p>
                <p>Example of multi-row and multi-column matrix:</p>
                <p><span className="text-sky-500 hover:text-sky-600">1, 2, 4, 6</span></p>
                <p><span className="text-sky-500 hover:text-sky-600">3, 1, 7, 9</span></p>
                <p><span className="text-sky-500 hover:text-sky-600">5, 8, 6, 1</span></p><hr />
                <p>Example of a one-row matrix:</p>
                <p><span className="text-red-500 hover:text-red-600">1, 2, 4, 6</span></p><hr />
                <p>Example of a one-column matrix:</p>
                <p><span className="text-purple-500 hover:text-purple-600"> 4</span></p>
                <p><span className="text-purple-500 hover:text-purple-600"> 9</span></p>
                <p><span className="text-purple-500 hover:text-purple-600"> 6</span></p><hr />
                <p>For <b>systems of linear equations</b>, the first matrix is multi-row and multi-column but the
                    <span className="text-blue-500 hover:text-blue-600"> second matrix is a one column matrix</span>.
                    Under the hood, the system of linear systems use the characters from a to t as variables.</p>
                <p>For instance, if we enter Matrix A as :</p>
                <p><span className="text-sky-500 hover:text-sky-600">2, -1, 3</span></p>
                <p><span className="text-sky-500 hover:text-sky-600">1, -3, -2</span></p>
                <p><span className="text-sky-500 hover:text-sky-600">3, 2, -1</span></p>
                <p>And Matrix B as :</p>
                <p><span className="text-sky-500 hover:text-sky-600">9</span></p>
                <p><span className="text-sky-500 hover:text-sky-600">0</span></p>
                <p><span className="text-sky-500 hover:text-sky-600">-1</span></p>
                <p>The systems of linear equations we are trying to solve is : </p>
                <p><span className="text-sky-500 hover:text-sky-600">2a - b + 3c = 9</span></p>
                <p><span className="text-sky-500 hover:text-sky-600">a - 3b - 2c = 0</span></p>
                <p><span className="text-sky-500 hover:text-sky-600">3a +2b - c = -1</span></p>
            </div>
        </>
    )
}

function AnalyticalExample(){ 
    //{/**x^3/3 + x^2/2 + x */}
    return (
        <>
            <div className="card-body">
            <p>Try this: <span className="text-purple-500 hover:text-purle-600">x^2 + x + 1</span> </p>
            </div>
        </>
    )
}

function DerivativeExample(){ 
    //{/** */}
    return (
        <>
            <div className="card-body">
            <p>Try this: <span className="text-purple-500 hover:text-purle-600">1/x</span> </p>
            </div>
        </>
    )
}



function NumericalExample(){ 
    //{/**0.73 */}
    return (
        <>
            <div className="card-body">
            <p>Try this: 
            function: <span className="text-purple-500 hover:text-purle-600"> sin ( cos(x) )</span> 
            , lower: <span className="text-purple-500 hover:text-purle-600"> 0</span> 
            , upper: <span className="text-purple-500 hover:text-purle-600"> 1</span> </p>
            </div>
        </>
    )
}


function LimitExample(){ 
    //{/** 50 */}
    return (
        <>
            <div className="card-body">
            <p>Try this: 
            function: <span className="text-purple-500 hover:text-purle-600"> 8-3*x+12*x^2 </span> 
            , bound (x -&gt; ): <span className="text-purple-500 hover:text-purle-600"> 2</span>  </p>
            </div>
        </>
    )
}

export { CalculusOneEntry, CalculusTwoEntries, CalculusThreeEntries, CalculusOneMatrixEntry, CalculusTwoMatricesEntries, 
    AngouriPolynomialGuide, AnalyticalExample, NumericalExample, DerivativeExample, LimitExample, AngouriMatrixGuide,
     CalculusOneMatrixInputEntry, LinearSystemGuide, CalculusLinearSystems}