import {React} from "react";




function Products() {





  return (
    <>
        <div className="py-2 flex justify-center items-center  bg-base-100 mainDiv" >
          
            <div className="py-4 lg:py-6">

                <div className="space-y-6 py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
                    <h2 className="flex justify-center items-center"><b>Our products recommendations</b></h2>
                    <p className="text-black-500 dark:text-white">
                    We often hear that mathematics is complicated. 
                    One needs practice and perseverance to grasp the core concepts of mathematics fully.
                    But can various tools, software solutions, products, and services greatly ease your mathematics learning?
                    Of course, they can! 
                    We designed this facile math website with this goal in mind.
                    Nevertheless, our website alone won't and can't cover all the sub-disciplines of mathematics.
                    That is why you will find below some books and products we recommend. 
                    We only recommend products and services that we know or trust to be of high-quality.
                    </p>
                    <h2 className="flex  justify-center items-center text-purple-400"><b><u>Books / TextBooks</u></b></h2>
                    <div className="flex flex-wrap justify-center items-center space-x-4 space-y-4">
                        <a  className=""  target="_blank" href="https://amzn.to/3GEzKpA"><button className="btn btn-primary px-4">Show me Calculus books</button></a>
                        <a  className=""  target="_blank" href="https://amzn.to/3TlgVj0"><button className="btn btn-accent">Show me Algebra books</button></a>
                        <a  className=""  target="_blank" href="https://amzn.to/4acYov8"><button className="btn btn-neutral">Show me Statistics books</button></a>
                        <a  className=""  target="_blank" href="https://amzn.to/3Rzenwa"><button className="btn btn-primary">Show me Geometry books</button></a>
                        <a  className=""  target="_blank" href="https://amzn.to/3Tg4tB3"><button className="btn bg-red-500 text-white">Show me Financial Math books</button></a>

                    </div><br />


                    <h2 className="flex  justify-center items-center text-purple-400"><b><u>Surveying Instruments</u></b></h2>
                    <div>
            <ul className="menu">
                <li>
                <a href="https://amzn.to/49Mcyli" className="text-sky-500 hover:text-red-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#F56565" height="24" viewBox="0 -960 960 960" width="24"><path d="M240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h480q33 0 56.5 23.5T800-800v640q0 33-23.5 56.5T720-80H240Zm0-80h480v-640h-80v280l-100-60-100 60v-280H240v640Zm0 0v-640 640Zm200-360 100-60 100 60-100-60-100 60Z"/></svg>
                Leica Geosystems 840386 NA532 Automatic Optical Level</a>
                </li>
                <li>
                <a href="https://amzn.to/3UsQloj" className="text-sky-500 hover:text-red-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#F56565" height="24" viewBox="0 -960 960 960" width="24"><path d="M240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h480q33 0 56.5 23.5T800-800v640q0 33-23.5 56.5T720-80H240Zm0-80h480v-640h-80v280l-100-60-100 60v-280H240v640Zm0 0v-640 640Zm200-360 100-60 100 60-100-60-100 60Z"/></svg>
                TRIMBLE SPECTRA PRECISION LL300 SELF-LEVELING ROTARY LASER LEVEL</a>
                </li>
            </ul>
            <a  className=""  target="_blank" href="https://amzn.to/3W7xeB8"><button className="btn btn-info w-full">Show me more Surveying instruments</button></a>
            </div>

                </div>
            </div>
        </div>
    </>
  );
  }
  export default   Products;