import {React, useState} from "react";



function StatisticsOneEntry({endpoint}){
    const [inputText, setInputText] = useState('')
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const dataset = inputText
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ RawText: dataset })
        };
        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/statistics/${endpoint}`;
   
        if(inputText !== ''){
            fetch(url, requestOptions)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter coma-separated values in the text area above!");
        }      
              
    }

    return(
        <div>
            <div>
                <textarea className="textarea textarea-bordered  w-full h-48" placeholder="Type in or paste coma-separated values. Ex: 1.4 , 3 , 7.5" value={inputText} onChange={event => setInputText(event.target.value)}>Type in or paste coma-separated values. Ex: 1.4 , 3 , 7.5</textarea>
            </div>
            <div className="py-2">
                {endpoint === "mean" && <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>}
                {endpoint === "geomean" && <button className="btn bg-red-500 hover:bg-red-800 text-white w-full" onClick={startCalculate}>Calculate</button>}
                {endpoint === "harmean" && <button className="btn bg-purple-500 hover:bg-purple-800 text-white w-full" onClick={startCalculate}>Calculate</button>}
                {endpoint === "median" && <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>}
                {endpoint === "mode" && <button className="btn bg-red-500 hover:bg-red-800 text-white w-full" onClick={startCalculate}>Calculate</button>}
                {endpoint === "popvar" && <button className="btn bg-purple-500 hover:bg-purple-800 text-white w-full" onClick={startCalculate}>Calculate</button>}
                {endpoint === "popstd" && <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>}
                {endpoint === "sampvar" && <button className="btn bg-red-500 hover:bg-red-800 text-white w-full" onClick={startCalculate}>Calculate</button>}
                {endpoint === "sampstd" && <button className="btn bg-purple-500 hover:bg-purple-800 text-white w-full" onClick={startCalculate}>Calculate</button>}
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold  w-full" value={outputText} onChange={event => setOutputText(event.target.value)}>Type in or paste coma-separated values. Ex: 1.4 , 3 , 7.5</textarea>
            </div>
        </div>
    )
}

function StatisticsTwoEntries({endpoint, inputPlaceHolder}){
    const [searchItem, setSearchItem] = useState('')
    const [inputText, setInputText] = useState('')
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const dataset = inputText;
        const oneValue = searchItem;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ RawText: dataset, AValue: oneValue })
        };
        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/statistics/${endpoint}`;
   
        if(dataset !== '' && oneValue !== ''){
            fetch(url, requestOptions)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter values!");
        }      
              
    }

    return(
        <div>
            <div>
                <textarea className="textarea textarea-bordered  w-full h-48" placeholder="Type in or paste coma-separated values. Ex: 1.4 , 3 , 7.5" value={inputText} onChange={event => setInputText(event.target.value)}></textarea>
                <input  type="text" placeholder={inputPlaceHolder} className="input input-bordered w-full"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
            </div>
            <div className="py-2">
                {endpoint === "nper" && <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>}
                {endpoint === "zpop" && <button className="btn bg-red-500 hover:bg-red-800 text-white w-full" onClick={startCalculate}>Calculate</button>}
                {endpoint === "zsamp" && <button className="btn bg-purple-500 hover:bg-purple-800 text-white w-full" onClick={startCalculate}>Calculate</button>}
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold  w-full" value={outputText} onChange={event => setOutputText(event.target.value)}>Type in or paste coma-separated values. Ex: 1.4 , 3 , 7.5</textarea>
            </div>
        </div>
    )
}

function StatisticsThreeEntries({endpoint}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [searchItem3, setSearchItem3] = useState('')
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const score = searchItem1;
        const mean = searchItem2;
        const std = searchItem3;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Score: score, Mean: mean, STD:std })
        };
        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/statistics/${endpoint}`;
   
        if(score !== '' && mean !== '' && std !== ''){
            fetch(url, requestOptions)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter coma-separated values in the text area above!");
        }      
              
    }

    return(
        <div>
            <div className="space-y-2 py-2">
                
                <input  type="text" placeholder="Score" className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder="Mean" className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                <input  type="text" placeholder="Standard deviation" className="input input-bordered w-full"  value={searchItem3} onChange={event => setSearchItem3(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function DistributionFourEntries({endpoint, placeHolder1, placeHolder2, placeHolder3, placeHolder4}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [searchItem3, setSearchItem3] = useState('')
    const [searchItem4, setSearchItem4] = useState('')
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const entry1 = searchItem1;
        const entry2 = searchItem2;
        const entry3 = searchItem3;
        const entry4 = searchItem4;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/statistics/${endpoint}/${entry1}/${entry2}/${entry3}/${entry4}`;
   
        if(entry1 !== '' && entry2 !== '' && entry3 !== '' && entry4 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>
            <div className="space-y-2 py-2">
                
                <input  type="text" placeholder={placeHolder1} className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder2} className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder3} className="input input-bordered w-full"  value={searchItem3} onChange={event => setSearchItem3(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder4} className="input input-bordered w-full"  value={searchItem4} onChange={event => setSearchItem4(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-red-500 hover:bg-red-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full h-48" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}


function DistributionThreeEntries({endpoint, placeHolder1, placeHolder2, placeHolder3}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [searchItem3, setSearchItem3] = useState('')
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const entry1 = searchItem1;
        const entry2 = searchItem2;
        var entry3 = searchItem3;
        if (entry3 === '') {entry3 = 'NULL'};
        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/statistics/${endpoint}/${entry1}/${entry2}/${entry3}`;
   
        if(entry1 !== '' && entry2 !== '' && entry3 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>
            <div className="space-y-2 py-2">
                
                <input  type="text" placeholder={placeHolder1} className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder2} className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder3} className="input input-bordered w-full"  value={searchItem3} onChange={event => setSearchItem3(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full h-48" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function DistributionTwoEntries({endpoint, placeHolder1, placeHolder2}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const entry1 = searchItem1;
        var entry2 = searchItem2;

        if (entry2 === '') {entry2 = 'NULL'};
        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/statistics/${endpoint}/${entry1}/${entry2}`;
   
        if(entry1 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>
            <div className="space-y-2 py-2">
                
                <input  type="text" placeholder={placeHolder1} className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder2} className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-purple-700 hover:bg-purple-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full h-48" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function DistributionOneEntry ({endpoint, placeHolder1}){
    const [searchItem1, setSearchItem1] = useState('')
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const entry1 = searchItem1;



        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/statistics/${endpoint}/${entry1}`;
   
        if(entry1 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>
            <div className="space-y-2 py-2">
                
                <input  type="text" placeholder={placeHolder1} className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                
            </div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full h-48" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}


export {StatisticsOneEntry, StatisticsTwoEntries, StatisticsThreeEntries, DistributionOneEntry, DistributionTwoEntries, DistributionThreeEntries, DistributionFourEntries};