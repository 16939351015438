import {React, useState} from "react";


function TrigonometryOneEntry({calculus}){
    const [searchItem1, setSearchItem1] = useState('')

    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/trigonometry/equation/${calculus}/${item1}`;
   
        if(item1 !== '' ){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>
            <div>
                <input  type="text" placeholder="a" className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}



function TrigonometryTwoEntries({calculus}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')

    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1;
        const item2 = searchItem2;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/trigonometry/equation/${calculus}/${item1}/${item2}`;
   
        if(item1 !== '' && item2 !== '' ){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>
            <div className="space-y-2">
                
                <input  type="text" placeholder="a" className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder="b" className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-red-500 hover:bg-red-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function TrigonometryThreeEntries({calculus}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [searchItem3, setSearchItem3] = useState('')

    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1;
        const item2 = searchItem2;
        const item3 = searchItem3;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/trigonometry/equation/${calculus}/${item1}/${item2}/${item3}`;
   
        if(item1 !== '' && item2 !== '' ){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>
            <div className="space-y-2">
                
                <input  type="text" placeholder="a" className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder="b" className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                <input  type="text" placeholder="c" className="input input-bordered w-full"  value={searchItem3} onChange={event => setSearchItem3(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-purple-600 hover:bg-purple-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}



export {TrigonometryOneEntry, TrigonometryTwoEntries, TrigonometryThreeEntries};