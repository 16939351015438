import {React, useState} from "react";
import AlgebraAffiliate from "./AlgebraAffiliate";


function Inequality({endpoint, sign}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1;
        const item2 = searchItem2;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/algebra/inequalities/${endpoint}/${item1}/${item2}`;
   
        if(item1 !== '' && item2 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        } 
    }

    return(
        <div>

            <div className="flex flex-row py-2">
                <div className="basis-3/7">
                    <input  type="text" placeholder="ax+b" className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                </div>
                <div className="basis-1/7">
                    <p className="items-center m-2">{sign}</p>
                </div>
                <div className="basis-3/7">
                <input  type="text" placeholder="cx+d" className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                </div>
                
            </div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function DoubleInequality({endpoint, sign1, sign2}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [searchItem3, setSearchItem3] = useState('')
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1;
        const item2 = searchItem2;
        const item3 = searchItem3;
        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/algebra/inequalities/${endpoint}/${item1}/${item2}/${item3}`;
   
        if(item1 !== '' && item2 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        } 
    }

    return(
        <div>

            <div className="flex flex-row py-2">
                <div className="basis-3/11">
                    <input  type="text" placeholder="ax+b" className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                </div>
                <div className="basis-1/11">
                    <p className="items-center m-2">{sign1}</p>
                </div>
                <div className="basis-3/11">
                <input  type="text" placeholder="cx+d" className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                </div>
                <div className="basis-1/11">
                    <p className="items-center m-2">{sign2}</p>
                </div>
                <div className="basis-3/11">
                <input  type="text" placeholder="ex+f" className="input input-bordered w-full"  value={searchItem3} onChange={event => setSearchItem3(event.target.value)}></input>
                </div>
                
            </div>
            <div className="py-2">
                <button className="btn bg-purple-600 hover:bg-purple-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}




function AlgebraInequality(){
    const [selectedText, setSelectedText] = useState('abcdinf') //default selected value
    return(
        <>
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Inequalities</h2>
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="abcdinf">Solve ax+b &lt; cx + d </option>
                                <option value="abcdinfeq">Solve ax+b &lt;= cx + d </option>
                                <option value="abcdefinf">Solve ax+b &lt; cx + d &lt; ex + f </option>
                                <option value="abcdefinfeq1">Solve ax+b &lt;= cx + d &lt; ex + f </option>
                                <option value="abcdefinfeq2">Solve ax+b &lt; cx + d &lt;= ex + f </option>
                                <option value="abcdefinfeqeq">Solve ax+b &lt;= cx + d &lt;= ex + f </option>
                            </select>
                        </div>
                    </div>
                        <div>
                            {selectedText==="abcdinf" && <> <Inequality endpoint={"abcdinferior"} sign={"<"} /></>}     
                            {selectedText==="abcdinfeq" && <> <Inequality endpoint={"abcdinferioreq"} sign={"<="} /></>} 
                            {selectedText==="abcdefinf" && <> <DoubleInequality endpoint={"abcdefinferior"} sign1={"<"} sign2={"<"} /></>} 
                            {selectedText==="abcdefinfeq1" && <> <DoubleInequality endpoint={"abcdefinferioreq1"} sign1={"<="} sign2={"<"} /></>} 
                            {selectedText==="abcdefinfeq2" && <> <DoubleInequality endpoint={"abcdefinferioreq2"} sign1={"<"} sign2={"<="} /></>} 
                            {selectedText==="abcdefinfeqeq" && <> <DoubleInequality endpoint={"abcdefinferioreqeq"} sign1={"<="} sign2={"<="} /></>} 
                        </div>
                        <div className="card shadow w-full">
                            <p className="px-2">A polynomial must be entered in the form <span className="text-sky-500 hover:text-sky-600">a</span> x + <span className="text-sky-500 hover:text-sky-600">b</span></p>
                            <p className="px-2">Example: <span className="text-sky-500 hover:text-sky-600">2x+11</span></p>
                        </div>
                        <div className="py-12"> 
                        {/*  <AlgebraAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    
                </div>
            </section>
            </>
    )
}

export default AlgebraInequality;