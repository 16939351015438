import {React, useState} from "react";
import circle_image from "../../assets/images/circle.png";
import inscribed_image from "../../assets/images/inscricircle.png";
import sphere_image from "../../assets/images/sphere.png";
import pentagon_image from "../../assets/images/pentagon.png";
import icosahedron_image from "../../assets/images/icosahedron.png";
import rectangle_image from "../../assets/images/rectangle.png";
import triangle_image from "../../assets/images/triangle.png";
import cylinder_image from "../../assets/images/cylinder.png";
import annulus_image from "../../assets/images/annulus.png";
import polygon_image from "../../assets/images/polygon.png";

import {SurfaceOneEntry, SurfaceTwoEntries} from "../surface/SurfaceTemplates";

function SurfaceArea(){
    const [selectedText, setSelectedText] = useState('circlearea') //default selected value
    return(
        <>

            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Areas</h2>
                    <div className="flex flex-col text-center w-full mb-12">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="circlearea">Area of a circle</option>
                                <option value="inscribedarea">Area of an inscribed circle</option>
                                <option value="spherearea">Area of a sphere</option>
                                <option value="pentagonarea">Area of a pentagon</option>
                                <option value="icosahedronarea">Area of an icosahedron</option>
                                <option value="rectanglearea">Area of a rectangle</option>
                                <option value="trianglearea">Area of a triangle</option>
                                <option value="cylinderarea">Area of a cylinder</option>
                                <option value="annulusarea">Area of an annulus</option>
                                <option value="polygonarea">Area of a polygon</option>
                            </select>
                        </div>
                        <div>
                            {selectedText==="circlearea" && <SurfaceOneEntry endpoint={"circlearea"} image={circle_image} placeHolder1={"Radius R"}></SurfaceOneEntry>}
                            {selectedText==="inscribedarea" && <SurfaceOneEntry endpoint={"inscribedarea"} image={inscribed_image} placeHolder1={"Side L"}></SurfaceOneEntry>}
                            {selectedText==="spherearea" && <SurfaceOneEntry endpoint={"spherearea"} image={sphere_image} placeHolder1={"Radius R"}></SurfaceOneEntry>}
                            {selectedText==="pentagonarea" && <SurfaceOneEntry endpoint={"pentagonarea"} image={pentagon_image} placeHolder1={"Side L"}></SurfaceOneEntry>}
                            {selectedText==="icosahedronarea" && <SurfaceOneEntry endpoint={"icosahedronarea"} image={icosahedron_image} placeHolder1={"Side L"}></SurfaceOneEntry>}
                            {selectedText==="rectanglearea" && <SurfaceTwoEntries endpoint={"rectanglearea"} image={rectangle_image} placeHolder1={"Length L"} placeHolder2={"Width W"}></SurfaceTwoEntries>}
                            {selectedText==="trianglearea" && <SurfaceTwoEntries endpoint={"trianglearea"} image={triangle_image} placeHolder1={"Base B"} placeHolder2={"Height H"}></SurfaceTwoEntries>}
                            {selectedText==="cylinderarea" && <SurfaceTwoEntries endpoint={"cylinderarea"} image={cylinder_image} placeHolder1={"Radius R"} placeHolder2={"Height H"}></SurfaceTwoEntries>}
                            {selectedText==="annulusarea" && <SurfaceTwoEntries endpoint={"annulusarea"} image={annulus_image} placeHolder1={"Inner radius r"} placeHolder2={"Outer radius R"}></SurfaceTwoEntries>}
                            {selectedText==="polygonarea" && <SurfaceTwoEntries endpoint={"polygonarea"} image={polygon_image} placeHolder1={"Number of sides"} placeHolder2={"Side L"}></SurfaceTwoEntries>}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>
            </>
    )
}

export default SurfaceArea;