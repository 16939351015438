import {LinearSystemGuide, CalculusLinearSystems}
 from "./CalculusTemplates";
    import {React} from "react";
    import CalculusAffiliate from "./CalculusAffiliate";



function CalculusSystem(){
    return(
        <>
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Systems of Linear Equations</h2>
                        <div>
                            <> <CalculusLinearSystems endpoint={"linearsystems"} sign={"X="} ></CalculusLinearSystems>   </>     
                        </div>
                        <div className="card shadow w-full">
                             <LinearSystemGuide ></LinearSystemGuide> 
                        </div>
                        <div className="py-12"> 
                        {/*  <CalculusAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default CalculusSystem;