import {React, useState, useEffect} from "react";



function ConstantDisplay ({name, details}){
    return (
        <>
            <div className="py-4">
                <li>
                    <details>
                        <summary>
                        {name}
                        </summary>
                        <div className="px-1 py-2 mx-auto max-w-lg">
                            <div className="card shadow w-full">
                                <div className="card-body ">
                                    <h2 className="card-title"> </h2>
                                    <div dangerouslySetInnerHTML={{__html: details}}></div>
                                </div>
                            </div>
                        </div>
                    </details>
                </li>
                
            </div>
            <hr></hr>
        </>
    )
}

function ScienceTemplate({endpoint}) {
  const [searchItem, setSearchItem] = useState('')

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/constants/${endpoint}`;

  useEffect(() => {
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [url])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {




  return (
    <>
      <div className="px-2 py-2 flex justify-center items-center">
        <input  type="text" placeholder="Search here" className="input input-bordered input-info w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
      </div>
      <div className="py-6 flex justify-center items-center">
        <ul className="menu menu-vertical px-1 w-full  max-w-lg">
          {items.filter((constant) => //items here
              {
                if(searchItem === "") {
                  return constant;
                }
                else if 
                (constant.Name.toLowerCase().includes(searchItem.toLowerCase())) {
                  return constant;
                }
                else return null
                
              }).map(constant => 
          <ConstantDisplay key={constant.Name} name={constant.Name} 
            details={constant.Details} >
            </ConstantDisplay>)}
        </ul>

      </div>

          
    </>
  );}
  }
  export default   ScienceTemplate;