import {FinanceTwoEntries,FinanceThreeEntries}
 from "./FinanceTemplates";
    import {React, useState} from "react";
    import FinanceAffiliate from "./FinanceAffiliate";



function FinanceLiquidity(){
    const [selectedText, setSelectedText] = useState('CashRation') //default selected value
    return(
        <>
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Liquidity</h2>
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                            <option value="CashRation">Cash Ratio </option>
                            <option value="CurrentRatio">Current Ratio </option>
                            <option value="OperatingCashFlow">Operating Cash Flow Ratio </option>
                            <option value="QuickRatio">Quick Ratio </option>
                               
                            </select>
                        </div>
                    </div>
                        <div>
                        {selectedText==="CashRation" && <> <FinanceThreeEntries endpoint={"liquidity/cashrationfromcashmarketablesecuritiesandcurrentliabilities"} placeHolder1={"Cash"}  placeHolder2={"Marketable Securities"} placeHolder3={"Current Liabilities"}></FinanceThreeEntries>   </>}    
                        {selectedText==="CurrentRatio" && <> <FinanceTwoEntries endpoint={"liquidity/currentratiofromcurrentassetsandcurrentliabilties"} placeHolder1={"Current Assets"}  placeHolder2={"Current Liabilities"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="OperatingCashFlow" && <> <FinanceTwoEntries endpoint={"liquidity/operatingcashflowratiofromoperatingcashflowandtotaldebts"} placeHolder1={"Operating Cash Flow"}  placeHolder2={"Total Debts"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="QuickRatio" && <> <FinanceThreeEntries endpoint={"liquidity/quickratiofromcurrentassetsinventoriesandcurrentliabilities"} placeHolder1={"Current Assets"}  placeHolder2={"Inventories"} placeHolder3={"Current Liabilities"}></FinanceThreeEntries>   </>}    



                        </div>
                        <div className="py-12"> 
                        {/*  <FinanceAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default FinanceLiquidity;