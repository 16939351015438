import {AlgebraOneEntry} from "./AlgebraTemplates";
    import {React} from "react";
    import AlgebraAffiliate from "./AlgebraAffiliate";



function AlgebraPrime(){
    return(
        <>
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Prime numbers</h2>
                        <div>
                            <AlgebraOneEntry endpoint={"prime"}  placeHolder1={"Enter a positive integer"} ></AlgebraOneEntry> 
                        </div>
                        <div className="py-12"> 
                        {/*  <AlgebraAffiliate />  */}
                        </div>

                        <div className="py-24">
                            
                        </div>

                    
                    
                </div>
            </section>
            </>
    )
}

export default AlgebraPrime;