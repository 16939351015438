import { CalculusOneEntry,  CalculusThreeEntries, 
    AngouriPolynomialGuide, AnalyticalExample, NumericalExample}
 from "./CalculusTemplates";
    import {React, useState} from "react";
    import CalculusAffiliate from "./CalculusAffiliate";



function CalculusIntegral(){
    const [selectedText, setSelectedText] = useState('analytical') //default selected value
    return(
        <>
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Integration</h2>
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="analytical">Analytical Integration</option>
                                <option value="numerical">Numerical Integration</option>
                            </select>
                        </div>
                    </div>
                        <div>
                            {selectedText==="analytical" && <> <CalculusOneEntry endpoint={"integral/analytical"}  placeHolder1={"Enter a function "} ></CalculusOneEntry> <AnalyticalExample></AnalyticalExample>  </>}     
                            {selectedText==="numerical" && <><CalculusThreeEntries endpoint={"integral/numerical"}  placeHolder1={"Enter a function "} placeHolder2={"Lower bound"} placeHolder3={"Upper bound"}></CalculusThreeEntries> <NumericalExample></NumericalExample>  </>} 
                        </div>
                        <div className="card shadow w-full">
                             <AngouriPolynomialGuide ></AngouriPolynomialGuide> 
                        </div>
                        <div className="py-12"> 
                        {/*  <CalculusAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default CalculusIntegral;