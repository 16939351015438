import {React, useState} from "react";



function AlgebraOneEntry({endpoint, placeHolder1}){
    const [searchItem1, setSearchItem1] = useState('')
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');

        const item1 = searchItem1.replace("/", "|");

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/algebra/${endpoint}/${item1}`;
   
        if(item1 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <div className="space-y-2">
                <input  type="text" placeholder={placeHolder1} className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-blue-600 hover:bg-blue-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}


function AlgebraTwoEntries({endpoint, placeHolder1, placeHolder2}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')

    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1.replace("/", "|");;
        const item2 = searchItem2.replace("/", "|");;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/algebra/${endpoint}/${item1}/${item2}`;
   
        if(item1 !== '' && item2 !== '' ){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <div className="space-y-2">
                
                <input  type="text" placeholder={placeHolder1} className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder2} className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-red-500 hover:bg-red-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function AlgebraThreeEntries({endpoint, placeHolder1, placeHolder2, placeHolder3}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [searchItem3, setSearchItem3] = useState('')

    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1.replace("/", "|");;
        const item2 = searchItem2.replace("/", "|");;
        const item3 = searchItem3.replace("/", "|");;

        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/algebra/${endpoint}/${item1}/${item2}/${item3}`;
   
        if(item1 !== '' && item2 !== '' && item3 !== '' ){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <div className="space-y-2">
                
                <input  type="text" placeholder={placeHolder1} className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder2} className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder3} className="input input-bordered w-full"  value={searchItem3} onChange={event => setSearchItem3(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-purple-600 hover:bg-purple-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function AlgebraFourEntries({endpoint, placeHolder1, placeHolder2, placeHolder3, placeHolder4}){
    const [searchItem1, setSearchItem1] = useState('')
    const [searchItem2, setSearchItem2] = useState('')
    const [searchItem3, setSearchItem3] = useState('')
    const [searchItem4, setSearchItem4] = useState('')
    
    const [outputText, setOutputText] = useState('')

    const [error, setError] = useState(null);
    const [customError, setCustomError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const startCalculate =() => { 
        setButtonClicked(true);
        setIsLoaded(false);
        setOutputText('');
        setError('');
        setCustomError('');


        const item1 = searchItem1.replace("/", "|");;
        const item2 = searchItem2.replace("/", "|");;
        const item3 = searchItem3.replace("/", "|");;
        const item4 = searchItem4.replace("/", "|");;
        const url = `https://facilemathdo-4677w.ondigitalocean.app/facilemath/algebra/${endpoint}/${item1}/${item2}/${item3}/${item4}`;
   
        if(item1 !== '' && item2 !== '' && item3 !== '' && item4 !== ''){
            fetch(url)
            .then(res => res.text())
            .then(
            (result) => {
                setIsLoaded(true);
                setOutputText(result);      
            },
            (error) => {
                setIsLoaded(true);
                setError(error);   
            }); 
        }
        else
        {
            setIsLoaded(true);
            setCustomError("Please enter numerical values!");
        }      
              
    }

    return(
        <div>

            <div className="space-y-2">
                
                <input  type="text" placeholder={placeHolder1} className="input input-bordered w-full"  value={searchItem1} onChange={event => setSearchItem1(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder2} className="input input-bordered w-full"  value={searchItem2} onChange={event => setSearchItem2(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder3} className="input input-bordered w-full"  value={searchItem3} onChange={event => setSearchItem3(event.target.value)}></input>
                <input  type="text" placeholder={placeHolder4} className="input input-bordered w-full"  value={searchItem4} onChange={event => setSearchItem4(event.target.value)}></input>
            </div>
            <div className="py-2">
                <button className="btn bg-orange-700 hover:bg-orange-800 text-white w-full" onClick={startCalculate}>Calculate</button>
            </div>
            <div className="flex justify-center items-center">
                { customError && <p className="py-1 text-sm">{customError}</p> }
            </div>
            <div className="flex justify-center items-center">
                { error && <p className="py-1 text-sm">{error.message}</p> }
            </div>
            <div className="flex justify-center items-center">
                { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
            </div>
            <div>
                <textarea className="textarea textarea-bordered font-bold w-full" value={outputText} onChange={event => setOutputText(event.target.value)}></textarea>
            </div>
        </div>
    )
}

function AngouriPolynomialGuide(){
    return (
        <>
            <div className="card-body">
                
                <p>Use the following guideline to correctly enter functions and polynomials : </p>
                <p><b>Basic operators</b>: use <span className="text-sky-500 hover:text-sky-600">+ - * / </span> respectively for addition, subtraction, product and division. </p>
                <p><b>Power</b>: use <span className="text-sky-500 hover:text-sky-600">^ </span> x² should be typed in as <span className="text-sky-500 hover:text-sky-600">x^2</span> </p>
                <p><b>Variable</b>: use lowercase letters <span className="text-sky-500 hover:text-sky-600">x</span> or y (for sytem of equations) or any other.</p>
                <p><b>Square root</b>: use <span className="text-sky-500 hover:text-sky-600">sqrt</span> . example: sqrt(x)</p>
                <p><b>Trigonometric functions</b>: use <span className="text-sky-500 hover:text-sky-600">cos(x), sin(x), tan(x), tanh(x) ...</span></p>
                <p><b>Exponential</b>: use <span className="text-sky-500 hover:text-sky-600">e</span> . example: e^2 </p>
            </div>
        </>
    )
}
function RationalPolynomialGuide(){
    return (
        <>
            <div className="card-body">
                <p>
                    Enter the numerical values of <b>a,b,c</b> to find the rational roots of 
                    <span className="text-sky-500 hover:text-sky-600"> a</span>x² +
                    <span className="text-sky-500 hover:text-sky-600"> b</span>x +
                    <span className="text-sky-500 hover:text-sky-600"> c</span> = 0.
                </p>
                <p>For example, in the expression<b> 7x² - 4x + 5</b>, a = 7, b = -4 and c = 5 .</p>
                <p>In the expression<b> x²  + 2</b>, a = 1, b = 0 and c = 2 .</p>
                <p>In the expression<b> -x²  + 3x</b>, a = -1, b = 3 and c = 0 .</p>
            </div>
        </>
    )
}
//{/*x^2  - x - 6 */}
function ExpandExample(){ 
    return (
        <>
            <div className="card-body">
            <p>Try this expression: <span className="text-purple-500 hover:text-purle-600">(x + 2)*(x - 3)</span> </p>
            </div>
        </>
    )
}

function SimplifyExample(){ 
    //{/**x - 1 */}
    return (
        <>
            <div className="card-body">
            <p>Try this: <span className="text-purple-500 hover:text-purle-600">(x^3 + x^2 - x - 1)/(x^2 + 2x + 1)</span> </p>
            </div>
        </>
    )
}
function FactorExample(){ 
    //{/**(x - 1) (x^2+1) */}
    return (
        <>
            <div className="card-body">
            <p>Try this: <span className="text-purple-500 hover:text-purle-600">x*3 + x*y</span> </p>
            </div>
        </>
    )
}
function RealRootsExample(){ 
    //{/**-1 and 1 */}
    return (
        <>
            <div className="card-body">
            <p>Try this: <span className="text-purple-500 hover:text-purle-600">x^2 - 1</span> </p>
            </div>
        </>
    )
}
function OrdinaryExample(){
    return (
        <>
            <div className="card-body">
            <p>Polynomials must be entered in the form: <span className="text-purple-500 hover:text-purle-600">2x^2-3x+7</span> </p>
            </div>
        </>
    )
}
function SystemExample(){ 
    //{/**-1 and 1 */}
    return (
        <>
            <div className="card-body">
            <p>Try this: <span className="text-purple-500 hover:text-purle-600">x*y - 1   and x - 2 </span> </p>
            </div>
        </>
    )
}

export { AlgebraOneEntry, AlgebraTwoEntries, AlgebraThreeEntries,AlgebraFourEntries, AngouriPolynomialGuide, RationalPolynomialGuide,
ExpandExample, SimplifyExample, FactorExample, RealRootsExample, SystemExample, OrdinaryExample
};