import {AlgebraOneEntry, AlgebraTwoEntries,AngouriPolynomialGuide, RationalPolynomialGuide,
    ExpandExample, SimplifyExample, FactorExample, RealRootsExample, SystemExample, OrdinaryExample} from "./AlgebraTemplates";
    import AlgebraAffiliate from "./AlgebraAffiliate";
    import {React, useState} from "react";




function AlgebraPolynomial(){
    const [selectedText, setSelectedText] = useState('expand') //default selected value
    return(
        <>
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Polynomials</h2>
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="expand">Expand</option>
                                <option value="factor">Factorize</option>
                                <option value="simplify">Simplify</option>
                                <option value="add">Add</option>
                                <option value="subtract">Subtract</option>
                                <option value="multiply">Multiply</option>
                                <option value="power">Elevate a polynomial to the power n</option>
                                <option value="divide">Divide</option>
                                <option value="realroots">Roots of quadratic equation</option>
                                <option value="system">Solve two variables system of equations</option>
                            </select>
                        </div>
                    </div>
                        <div>
                            {selectedText==="expand" && <> <AlgebraOneEntry endpoint={"expand"}  placeHolder1={"Enter polynomial"} ></AlgebraOneEntry><ExpandExample/></>}     
                            {selectedText==="factor" && <><AlgebraOneEntry endpoint={"factorize"}  placeHolder1={"Enter polynomial"} ></AlgebraOneEntry><FactorExample/></>} 
                            {selectedText==="simplify" && <><AlgebraOneEntry endpoint={"simplify"}  placeHolder1={"Enter polynomial"} ></AlgebraOneEntry><SimplifyExample/></>} 
                            {selectedText==="add" && 
                            <><AlgebraTwoEntries endpoint={"add"}  placeHolder1={"Enter polynomial1"} placeHolder2={"Enter polynomial2"}></AlgebraTwoEntries><OrdinaryExample/></>} 
                            {selectedText==="subtract" && 
                            <><AlgebraTwoEntries endpoint={"subtract"}  placeHolder1={"Enter polynomial1"} placeHolder2={"Enter polynomial2"}></AlgebraTwoEntries><OrdinaryExample/></>} 
                            {selectedText==="multiply" && 
                            <><AlgebraTwoEntries endpoint={"multiply"}  placeHolder1={"Enter polynomial1"} placeHolder2={"Enter polynomial2"}></AlgebraTwoEntries><OrdinaryExample/></>}
                            {selectedText==="power" && 
                            <><AlgebraTwoEntries endpoint={"power"}  placeHolder1={"Enter polynomial"} placeHolder2={"n"}></AlgebraTwoEntries><OrdinaryExample/></>}
                            {selectedText==="divide" && 
                            <><AlgebraTwoEntries endpoint={"divide"}  placeHolder1={"Enter polynomial1"} placeHolder2={"Enter polynomial2"}></AlgebraTwoEntries><OrdinaryExample/></>}
                            {selectedText==="realroots" && <><AlgebraOneEntry endpoint={"realroots"}  placeHolder1={"Enter polynomial"} ></AlgebraOneEntry><RealRootsExample/></>} 
                            {selectedText==="system" && 
                            <><AlgebraTwoEntries endpoint={"system"}  placeHolder1={"First two variables (x,y) equation"} placeHolder2={"Second two variables (x,y) equation"}></AlgebraTwoEntries><SystemExample/></>}
                        </div>
                        <div className="card shadow w-full">
                            {selectedText !=="rationalroots" && <AngouriPolynomialGuide ></AngouriPolynomialGuide>}  
                            {selectedText==="rationalroots" && <RationalPolynomialGuide ></RationalPolynomialGuide>}  
                        </div>
                        <div className="py-12"> 
                        {/*  <AlgebraAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    
                </div>
            </section>
            </>
    )
}

export default AlgebraPolynomial;