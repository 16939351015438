import { CalculusOneEntry,
    AngouriPolynomialGuide, DerivativeExample}
 from "./CalculusTemplates";
    import {React} from "react";
    import CalculusAffiliate from "./CalculusAffiliate";



function CalculusDerivative(){
    return(
        <>
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Differentiation</h2>
                    <div className="flex flex-col text-center w-full mb-2">
                    </div>
                        <div>
                          <> <CalculusOneEntry endpoint={"differentiation"}  placeHolder1={"Enter a function "} ></CalculusOneEntry> <DerivativeExample></DerivativeExample>  </>    
                            
                        </div>
                        <div className="card shadow w-full">
                             <AngouriPolynomialGuide ></AngouriPolynomialGuide> 
                        </div>
                        <div className="py-12"> 
                        {/*  <CalculusAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default CalculusDerivative;