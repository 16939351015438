import {FinanceTwoEntries,FinanceThreeEntries}
 from "./FinanceTemplates";
    import {React, useState} from "react";
    import FinanceAffiliate from "./FinanceAffiliate";



function FinanceProfitability(){
    const [selectedText, setSelectedText] = useState('EfficiencyRatio') //default selected value
    return(
        <>
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Profitability</h2>
                    <div className="flex flex-col text-center w-full mb-2">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                            <option value="EfficiencyRatio">Efficiency Ratio </option>
                            <option value="GrossProfitMargin">Gross Profit Margin </option>
                            <option value="OperatingMargin">Operating Margin </option>
                            <option value="ProfitMargin">Profit Margin </option>
                            <option value="ReturnOnAssets">Return On Assets (ROA) </option>
                            <option value="ReturnOnCapital">Return On Capital (ROC) </option>
                            <option value="ReturnonEquity">Return on Equity (ROE) </option>
                            <option value="ReturnOn"> Return On Net Assets (RONA) </option>
                            <option value="Risk-AdjustedReturnOn">Risk-Adjusted Return On Capital (RAROC) </option>
                            <option value="ReturnonInvestment">Return on Investment (ROI) </option>
                            <option value="EarningsBeforeInterest," className="text-xs">Earn. Before Int., Tax., Depre. and Amorti. (EBITDA) </option>
                               
                            </select>
                        </div>
                    </div>
                        <div>
                        {selectedText==="EfficiencyRatio" && <> <FinanceTwoEntries endpoint={"profitability/efficiencyratiofromnoninterestexpenseandrevenue"} placeHolder1={"Non-Interest Expense"}  placeHolder2={"Revenue"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="GrossProfitMargin" && <> <FinanceTwoEntries endpoint={"profitability/grossprofitmarginfromgrossprofitandrevenue"} placeHolder1={"Gross Profit"}  placeHolder2={"Revenue"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="OperatingMargin" && <> <FinanceTwoEntries endpoint={"profitability/operatingmarginfromoperatingincomeandrevenue"} placeHolder1={"Operating Income"}  placeHolder2={"Revenue"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="ProfitMargin" && <> <FinanceTwoEntries endpoint={"profitability/profitmarginfromnetprofitandrevenue"} placeHolder1={"Net Profit"}  placeHolder2={"Revenue"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="ReturnOnAssets" && <> <FinanceTwoEntries endpoint={"profitability/returnonassetsfromnetincomeandtotalassets"} placeHolder1={"Net Income"}  placeHolder2={"Total Assets"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="ReturnOnCapital" && <> <FinanceThreeEntries endpoint={"profitability/returnoncapitalfromebittaxrateandinvestedcapital"} placeHolder1={"Earnings Before Interest and Taxes"}  placeHolder2={"Tax Rate"} placeHolder3={"Invested Capital"}></FinanceThreeEntries>   </>}    
                        {selectedText==="ReturnonEquity" && <> <FinanceTwoEntries endpoint={"profitability/returnonequityfromnetincomeandaverageshareholderequity"} placeHolder1={"Net Income"}  placeHolder2={"Average Shareholder Equit"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="ReturnOn" && <> <FinanceThreeEntries endpoint={"profitability/returnonnetassetsfromnetincomefixedassetsandworkingcapital"} placeHolder1={"Net Income"}  placeHolder2={"Fixed Assets"} placeHolder3={"Working Capital"}></FinanceThreeEntries>   </>}    
                        {selectedText==="Risk-AdjustedReturnOn" && <> <FinanceTwoEntries endpoint={"profitability/risk-adjustedreturnoncapitalfromexpectedreturnandeconomiccapital"} placeHolder1={"Expected Return"}  placeHolder2={"Economic Capital"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="ReturnonInvestment" && <> <FinanceTwoEntries endpoint={"profitability/returnoninvestmentfromgainandcost"} placeHolder1={"Gain"}  placeHolder2={"Cost"} ></FinanceTwoEntries>   </>}    
                        {selectedText==="EarningsBeforeInterest," && <> <FinanceThreeEntries endpoint={"profitability/earningsbeforeinteresttaxesdepreciationandamortizationfromebitdepreciationandamortization"} placeHolder1={"Earnings Before Interest and Taxes"}  placeHolder2={"Depreciation"} placeHolder3={"Amortization"}></FinanceThreeEntries>   </>}   



                        </div>
                        <div className="py-12"> 
                        {/*  <FinanceAffiliate />  */}
                        </div>
                        <div className="py-24">
 
                        </div>
                </div>
            </section>
            </>
    )
}

export default FinanceProfitability;