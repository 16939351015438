import React from "react";
function About() {
    return (
      <>
        <div className="py-6 sm:py-12">
          <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
            <div className="mx-auto max-w-md px-4">
              <h2><b> About Us</b></h2>
              <div className="divide-y divide-gray-300/50">
                <div className="space-y-6 py-8 text-base leading-7 text-black-500 dark:text-white">
                  {/**Besides the various tools available on this website, <b>we</b> also <a className="text-sky-500 hover:text-sky-600 italic" href="/products"> recommend products</a> that we know or trust to be of high quality. */}
                  <p>
                  The facile Math website aims to help people quickly solve complex math problems right in the browser. 
                  By creating this website, <b>we</b> want to give students and all mathematics amateurs various tools to help them do their quizzes, homework, or research.
                  
                  <b> But who are we? </b> <b>we</b> refers to our software company <a className="text-sky-400 hover:text-sky-600 font-bold" href="https://arfusoft.com/" target="_blank" rel="noreferrer">  Arfusoft.</a> It has conceived and built this website and all the different applications that it is referring to.
                    <span> Our company has years of experience in the development of:</span>
                  </p>
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-sky-100 bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round" >
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4">
                        Web
                        <code className="text-sm font-bold "> frontend</code> applications
                      </p>
                    </li>
                    <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-sky-100 bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4">
                        Web
                        <code className="text-sm font-bold "> backend/server</code> applications
                      </p>
                    </li>
                                <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-sky-100 bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4">
                        <code className="text-sm font-bold ">Mobile</code> applications for Android and IOS
                      </p>
                    </li>
                                            <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-sky-100 bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4">
                        <code className="text-sm font-bold ">Desktop</code> applications for Win and MAC
                      </p>
                    </li>
                  </ul>
                  <p><span className="text-sky-500 hover:text-sky-600 font-bold">Our area of expertise </span> is <span className="text-purple-500 font-bold">data-intensive fullstack web applications.</span>  </p>
                  <p>For more information, please get in touch with us by email at <a className="text-sky-400 hover:text-sky-600 font-bold" href="mailto:contact@arfusoft.com">contact@arfusoft.com</a> .</p>
                  <p>You can also visit our official website <a className="text-sky-400 hover:text-sky-600 font-bold" href="https://arfusoft.com/" target="_blank" rel="noreferrer">here</a> .</p>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </>
  
    );
  }
  export default About;