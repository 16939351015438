import { TopographyTwoEntries} from "./TopographyTemplates";
    import {React, useState} from "react";
    import sector_image from "../../assets/images/circlesector.png";
    import segment_image from "../../assets/images/circlesegment.png";
    import para_image from "../../assets/images/parasegment.png";
    import TopographyAffiliate from "./TopographyAffiliate";

function TopographyCircular(){
    const [selectedText, setSelectedText] = useState('circlesector') //default selected value
    return(
        <>
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-1 mx-auto max-w-lg">
                    <h2 className="card-title flex justify-center items-center py-2">Circular segments</h2>
                    <div className="flex flex-col text-center w-full mb-12">
                        <div className="py-1">
                            <select className="select select-bordered w-full" value={selectedText} onChange={event => setSelectedText(event.target.value)}>
                                <option value="circlesector">Circular sector</option>
                                <option value="circlesegment">Circular segment</option>
                                <option value="parabolicsegment">Parabolic segment</option>
                            </select>
                        </div>
                        <div>
                            {selectedText==="circlesector" && <TopographyTwoEntries endpoint={"segment/circlesector"} image={sector_image} placeHolder1={"Angle A in gradians"} placeHolder2="Radius R"></TopographyTwoEntries>}
                            {selectedText==="circlesegment" && <TopographyTwoEntries endpoint={"segment/circlesegment"} image={segment_image} placeHolder1={"Angle A in gradians"} placeHolder2="Radius R"></TopographyTwoEntries>}
                            {selectedText==="parabolicsegment" && <TopographyTwoEntries endpoint={"segment/parabolicsegment"} image={para_image} placeHolder1={"Distance a"} placeHolder2="Distance b"></TopographyTwoEntries>}
                           
                        </div>
                        <div className="py-12"> 
                        {/*  <TopographyAffiliate />  */}
                        </div>
                        <div className="py-24">
                            
                        </div>

                    
                    </div>
                </div>
            </section>
            </>
    )
}

export default TopographyCircular;