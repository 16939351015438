import React from "react";
import activity_image from "../../assets/images/activity.jpg";
import basic_image from "../../assets/images/basic_finance2.jpg";
import debt_image from "../../assets/images/debt.jpg";
import depreciation_image from "../../assets/images/depreciation2.jpg";
import liquidity_image from "../../assets/images/liquidity2.jpg";
import market_image from "../../assets/images/markets.jpg";
import profit_image from "../../assets/images/profitability.jpg";
import banking_image from "../../assets/images/banking1.jpg";
import corporate_image from "../../assets/images/corporate.jpg";
import general_image from "../../assets/images/general_finance.jpg";
import stocks_image from "../../assets/images/stocks2.jpg";


function FinanceMain(){
    return(
        <>
            {/**Description */}
            <section className="text-base-content body-font relative">
                <div className="container px-5 py-2 mx-auto">
                    <div className="flex flex-col text-justify w-full mb-12">
                        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">&nbsp;</h1>
                        <h2 className="text-black-500 dark:text-white">
                        Financial mathematics describes the application of mathematics and mathematical modeling to solve financial problems.
                        This page provides some hundred financial formulas.
                        They cover, among other things, calculations related to debt, activity, profitability, depreciation, liquidity, banking, corporate finance,  stocks and bonds and financial markets.
                        Here is an exhaustive list of the topics covered: 
                        </h2>
                        <ul className="space-y-4 py-4 px-12">
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm"> Activity</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm"> Basic finance</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm"> Debt</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm"> Depreciation</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm"> Liquidity</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm"> Financial Markets</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm">Profitability</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm">Banking </p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-purple-100 stroke-purple-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm">Corporate </p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm">General finance</p>
                            </li>
                            <li className="flex items-center">
                            <svg className="h-6 w-6 mr-1 flex-none fill-red-100 stroke-red-400 stroke-2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="11"></circle> <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" /></svg>
                                <p className="text-black-500 dark:text-white text-sm">Stocks and bonds</p>
                            </li>

                        </ul>
                        <div className=" dark:text-white">
                            <span>The native Application can be downloaded on the different applications stores: </span>
                            <a className="btn btn-ghost btn-circle btn-sm mr-4" href="https://play.google.com/store/apps/details?id=arfusoft.app.financial" target={"_blank"} rel="noopener noreferrer">
                                <svg width="20px" height="20px" viewBox="19.932999999999996 68.509 228.155 228.155" xmlns="http://www.w3.org/2000/svg"><path d="M101.885 207.092c7.865 0 14.241 6.376 14.241 14.241v61.09c0 7.865-6.376 14.24-14.241 14.24-7.864 0-14.24-6.375-14.24-14.24v-61.09c0-7.864 6.376-14.24 14.24-14.24z" fill="#a4c639"/><path d="M69.374 133.645c-.047.54-.088 1.086-.088 1.638v92.557c0 9.954 7.879 17.973 17.66 17.973h94.124c9.782 0 17.661-8.02 17.661-17.973v-92.557c0-.552-.02-1.1-.066-1.638H69.374z" fill="#a4c639"/><path d="M166.133 207.092c7.865 0 14.241 6.376 14.241 14.241v61.09c0 7.865-6.376 14.24-14.241 14.24-7.864 0-14.24-6.375-14.24-14.24v-61.09c0-7.864 6.376-14.24 14.24-14.24zM46.405 141.882c7.864 0 14.24 6.376 14.24 14.241v61.09c0 7.865-6.376 14.241-14.24 14.241-7.865 0-14.241-6.376-14.241-14.24v-61.09c-.001-7.865 6.375-14.242 14.241-14.242zM221.614 141.882c7.864 0 14.24 6.376 14.24 14.241v61.09c0 7.865-6.376 14.241-14.24 14.241-7.865 0-14.241-6.376-14.241-14.24v-61.09c0-7.865 6.376-14.242 14.241-14.242zM69.79 127.565c.396-28.43 25.21-51.74 57.062-54.812h14.312c31.854 3.073 56.666 26.384 57.062 54.812H69.79z" fill="#a4c639"/><path d="M74.743 70.009l15.022 26.02M193.276 70.009l-15.023 26.02" fill="none" stroke="#a4c639" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/><path d="M114.878 102.087c.012 3.974-3.277 7.205-7.347 7.216-4.068.01-7.376-3.202-7.388-7.176v-.04c-.011-3.975 3.278-7.205 7.347-7.216 4.068-.011 7.376 3.2 7.388 7.176v.04zM169.874 102.087c.012 3.974-3.277 7.205-7.347 7.216-4.068.01-7.376-3.202-7.388-7.176v-.04c-.011-3.975 3.278-7.205 7.347-7.216 4.068-.011 7.376 3.2 7.388 7.176v.04z" fill="#ffffff"/></svg>
                                </a>
                                <a className="btn btn-ghost btn-circle btn-sm" href="https://www.microsoft.com/store/apps/9P311G9RF8SQ" target={"_blank"} rel="noopener noreferrer">
                                <svg width="20px" height="20px" viewBox="-0.5 0 257 257" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><path d="M0 36.357L104.62 22.11l.045 100.914-104.57.595L0 36.358zm104.57 98.293l.08 101.002L.081 221.275l-.006-87.302 104.494.677zm12.682-114.405L255.968 0v121.74l-138.716 1.1V20.246zM256 135.6l-.033 121.191-138.716-19.578-.194-101.84L256 135.6z" fill="#00ADEF"/></svg>
                                </a> . <span  className="text-black-500 dark:text-white">You will find below the sub-topics of Financial Math. Please select one of the options to proceed further.</span> 
                            </div>
                    </div>
                </div>
            </section>
            {/**Topics */}
            <section className="text-base-content body-font dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-4">
                        <div className="p-4 lg:w-1/3">
                            {/**Activity*/}
                            <div className="card shadow w-80 m-4">
                                <figure>
                                    <img src={activity_image} alt="polynomial" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title"> Activity</h2>
                                    <p className="text-black-500 dark:text-white">
                                   
                                    </p>
                                    <a className="btn bg-blue-500 hover:bg-blue-800 text-white btn-sm" href="/finance-activity">Access</a>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:w-1/3">
                            {/**Basic Finance */}
                            <div className="card shadow w-80 m-4">
                                <figure>
                                    <img src={basic_image} alt="complex" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Basic Finance</h2>
                                    <p className="text-black-500 dark:text-white">
                                   
                                    </p>
                                    <a className="btn bg-orange-500 hover:bg-orange-800 text-white btn-sm" href="/finance-basic">Access</a>
                                </div>
                            </div>
                        </div>

                        <div className="p-4 lg:w-1/3">
                            {/**Debt*/}
                            <div className="card shadow w-80 m-4">
                                <figure>
                                    <img src={debt_image} alt="ineq" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Debt</h2>
                                    <p className="text-black-500 dark:text-white">
                              
                                    </p>
                                    <a className="btn bg-red-400 hover:bg-red-800 text-white btn-sm" href="/finance-debt">Access</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="text-base-content body-font dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-4">
                        <div className="p-4 lg:w-1/3">
                            {/**Depreciation*/}
                            <div className="card shadow w-80 m-4">
                                <figure>
                                    <img src={depreciation_image} alt="slope" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Depreciation</h2>
                                    <p className="text-black-500 dark:text-white">
                                 
                                    </p>
                                    <a className="btn bg-indigo-500 hover:bg-indigo-800 text-white btn-sm" href="/finance-depreciation">Access</a>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:w-1/3">
                            {/**Liquidity */}
                            <div className="card shadow w-80 m-4">
                                <figure>
                                    <img src={liquidity_image} alt="fibo" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Liquidity</h2>
                                    <p className="text-black-500 dark:text-white">
                            
                                    </p>
                                    <a className="btn bg-gray-500 hover:bg-gray-800 text-white btn-sm" href="/finance-liquidity">Access</a>
                                </div>
                            </div>
                        </div>

                        <div className="p-4 lg:w-1/3">
                            {/**Financial Markets*/}
                            <div className="card shadow w-80 m-4">
                                <figure>
                                    <img src={market_image} alt="prime" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Financial Markets</h2>
                                    <p className="text-black-500 dark:text-white">
                           
                                    </p>
                                    <a className="btn bg-blue-400 hover:bg-blue-800 text-white btn-sm" href="/finance-markets">Access</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-base-content body-font dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-4">
                        <div className="p-4 lg:w-1/3">
                            {/**Profitability*/}
                            <div className="card shadow w-80 m-4">
                                <figure>
                                    <img src={profit_image} alt="slope" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Profitability</h2>
                                    <p className="text-black-500 dark:text-white">
                                  
                                    </p>
                                    <a className="btn bg-teal-700 hover:bg-teal-800 text-white btn-sm" href="/finance-profitability">Access</a>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:w-1/3">
                            {/**Banking*/}
                            <div className="card shadow w-80 m-4">
                                <figure>
                                    <img src={banking_image} alt="slope" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Banking</h2>
                                    <p className="text-black-500 dark:text-white">
                                 
                                    </p>
                                    <a className="btn bg-gray-700 hover:bg-gray-800 text-white btn-sm" href="/finance-banking">Access</a>
                                </div>
                            </div>
                        </div>

                        <div className="p-4 lg:w-1/3">
                            {/**Corporate Finance*/}
                            <div className="card shadow w-80 m-4">
                                <figure>
                                    <img src={corporate_image} alt="slope" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Corporate Finance</h2>
                                    <p className="text-black-500 dark:text-white">
                                   
                                    </p>
                                    <a className="btn bg-blue-500 hover:bg-blue-800 text-white btn-sm" href="/finance-corporate">Access</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-base-content body-font dark:text-white">
                <div className="container px-5 py-4 mx-auto">
                    <div className="flex flex-wrap -m-4">
                        <div className="p-4 lg:w-1/3">
                            {/*General Finance*/}
                            <div className="card shadow w-80 m-4">
                                <figure>
                                    <img src={general_image} alt="slope" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">General Finance</h2>
                                    <p className="text-black-500 dark:text-white">
                                   
                                    </p>
                                    <a className="btn bg-gray-700 hover:bg-gray-800 text-white btn-sm" href="/finance-general">Access</a>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:w-1/3">
                            {/**Stocks and bonds*/}
                            <div className="card shadow w-80 m-4">
                                <figure>
                                    <img src={stocks_image} alt="slope" />
                                </figure>
                                <div className="card-body flex items-center">
                                    <h2 className="card-title">Stocks and bonds</h2>
                                    <p className="text-black-500 dark:text-white">
                                   
                                    </p>
                                    <a className="btn bg-blue-500 hover:bg-blue-800 text-white btn-sm" href="/finance-stocks">Access</a>
                                </div>
                            </div>
                        </div>

                        <div className="p-4 lg:w-1/3">

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default FinanceMain;
